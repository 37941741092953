
export interface AuditorRiskRatingDTO {
  id: number;
  title_en: string;
  title_ar: string;
  status: string;
  type: string;
  color_code: string;
}

export interface AuditorRiskRatingListDTO {
  id: number;
  title: string;
  status: string;
  type: string;
  color_code: string;
}
export interface AuditorRiskRatingDetailsDTO {
  id: number;
  title: string;
  status: string;
  type: string;
  color_code: string;
}

export interface AuditorRiskRatingFormDTO {
  id: number;
  title_en: string;
  title_ar: string;
  status: string;
  type: string;
  color_code: string;
}

export function convertResponseToAuditorRiskRatingDetailsDTO(
  response: any,
  lang: string
): AuditorRiskRatingDetailsDTO {
  return {
    id: response.id,
    title: response[`title_${lang}`],
    status: response.status,
    type: response.type,
    color_code: response.color_code,
  };
}

