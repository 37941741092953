import { FormGroup } from '@angular/forms';
import { MainConversionInterface } from '../../../../core/interfaces/conversion/main-conversion.interface';
import { Injectable } from '@angular/core';
import { CurrentUserPreferenceStore } from '../../../../core/store/current-user-preference.store';
import { convertResponseToUserMinimalDTO } from '../../../organization/users/dto/user.dto';
import {
  AuditUniverseAuditableItemDetailsDTO,
  AuditUniverseAuditableItemFormDTO,
  AuditUniverseAuditableItemListDTO,
  AuditUniverseMarkAuditableItemFormDTO,
} from '../dto/audit-universe-auditable-item.dto';
import { convertResponseToAuditorRiskRatingDetailsDTO } from '../../../settings/audit/dto/auditor-risk-rating.dto';
import { TableConversion } from '../../../../shared/conversion/table.conversion';
import { UserConversion } from '../../../organization/users/conversion/user.conversion';

@Injectable({
  providedIn: 'root',
})
export class AuditUniverseAuditableItemConversion
  implements
    MainConversionInterface<
      AuditUniverseAuditableItemListDTO,
      AuditUniverseAuditableItemFormDTO,
      AuditUniverseAuditableItemDetailsDTO
    >
{
  private lang: string;

  constructor(
    private currentUserPreferenceStore: CurrentUserPreferenceStore,
    private tableConv: TableConversion,
    private userConv: UserConversion
  ) {
    this.lang = this.currentUserPreferenceStore.languageKey;
  }

  resToList(response: any[]): AuditUniverseAuditableItemListDTO[] {
    if (!response) {
      return [];
    }
    const result = response.map((item) => {
      const { title, type } = this.getAuditableItemTypeTitle(item);

      return {
        id: item.id,
        title: title,
        comment: item.comment,
        type: type,
        auditor_risk_rating: this.tableConv.resToColordItem(
          item,
          'auditor_risk_rating'
        ),
        responsible_unit: item[`responsible_unit_title_${this.lang}`],
        created_at: item.created_at,
        updated_at: item.updated_at,
        responsible_user: this.userConv.resToUserMinimalDTO(
          item,
          'responsible_user'
        ),
        created_by: this.userConv.resToUserMinimalDTO(item, 'created_user'),
        updated_by: this.userConv.resToUserMinimalDTO(item, 'updated_user'),
      };
    });
    return result;
  }

  formGroupToForm(formGroup: FormGroup): AuditUniverseAuditableItemFormDTO {
    return {
      title_en: formGroup.get('Title')?.value,
      title_ar: formGroup.get('Title')?.value,
      comment: formGroup.get('Comment')?.value || null, // Provide a default value if parsing fails
      auditor_risk_rating_id: formGroup.get('AuditorRiskRating')?.value,
      responsible_user_id: formGroup.get('ResponsibleUserId')?.value,
    };
  }

  resToForm(response: any) {
    return {
      id: response.id,
      Title: response[`title_${this.lang}`],
      Comment: response.comment,
      AuditorRiskRating: response.auditor_risk_rating
        ? response.auditor_risk_rating.id
        : null,
      ResponsibleUserId: response.responsible_user
        ? response.responsible_user.id
        : null,
    };
  }

  resToDetails(response: any): AuditUniverseAuditableItemDetailsDTO {
    return {
      id: response.id,
      comment: response.comment,
      title: response[`title_${this.lang}`],
      auditor_risk_rating: convertResponseToAuditorRiskRatingDetailsDTO(
        response.auditor_risk_rating,
        this.lang
      ),
      created_at: response.created_at,
      updated_at: response.updated_at ? response.updated_at : null,
      created_by: convertResponseToUserMinimalDTO(
        response.created_by,
        this.lang
      ),
      updated_by: response.updated_by
        ? convertResponseToUserMinimalDTO(response.updated_by, this.lang)
        : null,
    };
  }

  markAsAuditableItemFormGroupToForm(
    formGroup: FormGroup,
    auditableItemType: string,
    auditableItemId: number
  ): AuditUniverseMarkAuditableItemFormDTO {
    return {
      process_ids: auditableItemType === 'process' ? [auditableItemId] : [],
      control_ids: auditableItemType === 'control' ? [auditableItemId] : [],
      document_ids: auditableItemType === 'document' ? [auditableItemId] : [],
      incident_register_ids:
        auditableItemType === 'incident_register' ? [auditableItemId] : [],
      compliance_requirement_ids:
        auditableItemType === 'compliance_requirement' ? [auditableItemId] : [],
      risk_register_ids: auditableItemType === 'risk' ? [auditableItemId] : [],
      user_ids: auditableItemType === 'user' ? [auditableItemId] : [],
      comment: formGroup.get('Comment')?.value || null,
      auditor_risk_rating_id: formGroup.get('AuditorRiskRating')?.value,
    };
  }

  getAuditableItemTypeTitle(item: any): { title: string; type: string } {
    let auditableItemTitle = '';
    let auditableItemType = '';

    switch (item.auditable_item_type) {
      case 'control':
        auditableItemTitle = item['control_title_' + this.lang];
        auditableItemType = 'Control';
        break;
      case 'process':
        auditableItemTitle = item['process_title_' + this.lang];
        auditableItemType = 'Process';
        break;
      case 'risk':
        auditableItemTitle = item.risk_register_title;
        auditableItemType = 'Risk';
        break;
      case 'compliance_requirement':
        auditableItemTitle = item.compliance_requirement_title;
        auditableItemType = 'Compliance Requirement';
        break;
      case 'compliance_register':
        auditableItemTitle = item.compliance_register_title;
        auditableItemType = 'Compliance Register';
        break;
      case 'incident':
        auditableItemTitle = item.incident_register_title;
        auditableItemType = 'Incident Register';
        break;
      case 'user':
        auditableItemTitle =
          item['user_first_name_' + this.lang] +
          ' ' +
          item['user_last_name_' + this.lang];
        auditableItemType = 'User';
        break;
      case 'document':
        auditableItemTitle = item['document_title_' + this.lang];
        auditableItemType = 'Policy and Procedure';
        break;
      case 'custom':
        auditableItemTitle = item['auditable_item_title_' + this.lang];
        auditableItemType = 'Custom';
        break;
      default:
        auditableItemTitle = item['auditable_item_title_' + this.lang];
        auditableItemType = 'Custom';
        break;
    }

    return { title: auditableItemTitle, type: auditableItemType };
  }
}
